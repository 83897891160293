.App {
  text-align: center;
  font: 14px "Lucida Grande", Helvetica, Arial, sans-serif;
  background-image:url("images/background-hexa.png");
  background-repeat:no-repeat;
  background-attachment:fixed;

}



